import Vue from "vue"
import Router from "vue-router"
import NProgress from "nprogress"
import "nprogress/nprogress.css"
//父容器
import Index from "../views/Index.vue"
import Login from "../views/Login.vue"
import test from "../views/test.vue"

// 首页管理
import indexView from "../views/index/View.vue"
import indexPage from "../views/index/Page.vue"
import classStudent from "../views/index/classStudent.vue"
import outIn from "../views/index/outIn/outIn.vue"
import examine from "../views/index/examine/examine.vue"
// import classDetail from "../views/index/classDetail.vue"

// 统计图
import echartView from "../views/echart/View.vue"
import lineChart from "../views/echart/LineChart.vue"

// 管理员管理
import adminView from "../views/admin/View.vue"
import adminSys from "../views/admin/AdminSys.vue"

//用户管理
import userView from "../views/user/View.vue"
import userSys from "../views/user/UserSys.vue"

//导入管理
import importView from "../views/import/View.vue"
import importPage from "../views/import/Page.vue"




Vue.use(Router)
var router = new Router({
	routes: [
		{
			path: '/',
			name: '/',
			component: Index,
			redirect: "/index/page",
			children: [
				//导入管理
				{
					path: "index",
					name: "index",
					component: indexView,
					children: [
						{
							component: () => import("@/views/import/Page.vue"),
							path: 'page',
							name: 'page',
							meta: { title: "导入管理" },
						},
						{
							component: () => import("@/views/statistics/allStatistic.vue"),
							path: 'allStatistic',
							name: 'allStatistic',
							meta: { title: "总数据统计" },
						},
						{
							component:()=>import("@/views/index/park.vue"),
							path:'park',
							name:'park',
							meta:{title:"园区管理"},
						},
						{
							component:()=>import("@/views/index/outIn/index.vue"),
							path:"outInIndex",
							name:"outInIndex",
							meta:{title:"校园出入记录"}
						},
						{
							component:()=>import("@/views/superAdmin/admin.vue"),
							path:"admin",
							name:"admin",
							meta:{title:"管理员管理"}
						},
						{
							component:()=>import("@/views/index/examine/index.vue"),
							path:"examineIndex",
							name:"examineIndex",
							meta:{title:"审核管理"}
						},
						{
							component:()=>import("@/views/statistics/index.vue"),
							path:'statistics',
							name:'statistics',
							meta:{title:"数据统计"}
						},
						{
							path:'examineIndex',
							name:'examineIndex',
							component:indexView,
							meta:{title:"审核管理"},
							children:[
								{
									component:()=>import("@/views/index/examine/examine.vue"),
									path:'examine',
									name:'examine',
									meta:{title:"班级详情"}
								}
							]
						},
						{
							path:'outInIndex',
							name:'outInIndex',
							component:indexView,
							meta:{title:"校园出入记录"},
							children:[
								{
									component:()=>import("@/views/index/outIn/outIn.vue"),
									path:'outIn',
									name:'outIn',
									meta:{title:"班级详情"}
								}
							]
						},
						{
							path: 'park',
							name: 'park',
							component: indexView,
							meta: { title: "园区管理" },
							children: [
								{
									component: () => import("@/views/index/classStudent.vue"),
									path: 'classStudent',
									name: 'classStudent',
									meta: { title: "班级管理" },
									
								},
								{
									component: () => import("@/views/index/student.vue"),
									path: 'student',
									name: 'student',
									meta: { title: "学生管理" },
								},
								{
									component:indexView,
									path:'classStudent',
									name:'classStudent',
									meta:{title:"班级管理"},
									children:[
										{
											component:()=>import("@/views/index/classDetail.vue"),
											path:"ctudent",
											name:"ctudent",
											meta:{title:"班级学生"}
										}
									]
								}
							]
						},
						{
							path: 'page',
							name: 'page',
							component: indexView,
							meta: { title: "导入管理" },
							children: [
								{
									component: () => import("@/views/import/newPage.vue"),
									path: 'newPage',
									name: 'newPage',
									meta: { title: "新园区详情" },
								},
								{
									path: 'newPage',
									name: 'newPage',
									component: indexView,
									meta: { title: "新园区详情" },
									children: [
										{
											component: () => import("@/views/import/classDetail.vue"),
											path: 'classDetail',
											name: 'classDetail',
											meta: { title: "班级详情" },
										}
									]
								}
							]
						}
					]
				}
			]
		},
		// {
		//   path: "/",
		//   name: "/",
		//   component: Index,
		//   redirect: "/index/page",
		//   children: [
		//     //首页管理
		//     {
		//       path: "index",
		//       name: "index",
		//       component: indexView,
		//       children: [
		//         {
		//           path: "page",
		//           name: "page",
		//           component: indexPage,
		//         },
		//         {
		//           path: "class",
		//           name: "class",
		//           component: classStudent,
		// 		  meta:{title:'班级管理'},
		// 		  children:[{
		// 			  path:"ouIns",
		// 			  name:'ouIns',
		// 			  component:outIn,
		// 			  meta:{title:'出入管理'}
		// 		  }]
		//         },
		//         {
		//           path: "outIn",
		//           name: "outIn",
		//           component: outIn,
		//         },
		//         {
		//           path: "examine",
		//           name: "examine",
		//           component: examine,
		//         },
		//         {
		//           path: "detail",
		//           name: "detail",
		//           component: classDetail,
		//         },
		//       ],
		//     },
		//     // 统计图
		//     {
		//       path: "echart",
		//       name: "echart",
		//       component: echartView,
		//       children: [
		//         {
		//           path: "lineChart",
		//           name: "lineChart",
		//           component: lineChart,
		//         },
		//       ],
		//     },

		// 管理员管理
		//     {
		//       path: "admin",
		//       name: "admin",
		//       component: adminView,
		//       children: [
		//         {
		//           path: "adminSys",
		//           name: "adminSys",
		//           component: adminSys,
		//         },
		//       ],
		//     },

		//     {
		//       path: "user",
		//       name: "user",
		//       component: userView,
		//       children: [
		//         {
		//           path: "userSys",
		//           name: "userSys",
		//           component: userSys,
		//         },
		//       ],
		//     },
		//   ],
		// },
		{
			path: "/login",
			name: "login",
			component: Login,
			meta: {
				title: "登录页面",
			},
		},
		{
			path: "/test",
			name: "test",
			component: test,
			meta: {
				title: "测试页面",
			},
		},
	],
	linkActiveClass: "active",
})

NProgress.configure({
	showSpinner: true
})
router.beforeEach((to, from, next) => {
	NProgress.start()
	if (to.path === "/login") {
		next()
	}
	if (sessionStorage.getItem("isLogin") != 1 && to.path != "/login") {
		next("/login")
	} else {
		next()
	}
	  /* 路由发生变化修改页面title */
	  if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})
router.afterEach(() => {
	NProgress.done()
})
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
