<template>
  <div>
    <div class="header">
      <div class="flex justify-between">
        <div style="padding-top: 0.6rem;">
          <breadcrumb></breadcrumb>
        </div>
        <div class="flex justify-center items-center" style="height: 40px; margin-right: 30px">
          <!-- icon消息提示 -->
          <!-- <el-badge v-if="value > 0" :value="value" class="item mr-6">
            <el-button class="el-icon-bell" size="small" circle style="font-size: 20px" @click="toApplay()"></el-button>
          </el-badge>

          <i v-else class="el-icon-bell mr-6" style="font-size: 20px" @click="toApplay()"></i> -->
          <!-- 头像  -->
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              <img src="../assets/img/11.png" style="height: 30px;width: 30px;margin-top: 15px;margin-right: 20px;"></img><i class="el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-circle-check"><span @click="update()">修改密码</span></el-dropdown-item>
              <el-dropdown-item icon="el-icon-circle-check"><span @click="logout()">退出登录</span></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <div class="truncate mr-1" style="font-size: 12px; width: 100px">
            {{ name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import breadcrumb from "./breadCrumb.vue"
  export default {
    components: {
      breadcrumb
    },
    data() {
      return {
        imgUrl:
          "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
        value: 0, //消息条数
        name: "名字在store.js里面拿取", //this.$store.state.user.name
        dialog:false
      };
    },
    // watch: {
    //   "$store.state.user.name": {
    //     handler() {
    //       this.name = this.$store.state.user.name;
    //     },
    //   },
    //   "$store.state.user.imgUrl": {
    //     handler() {
    //       this.imgUrl = this.$store.state.user.imgUrl;
    //     },
    //   },
    // },
    destroyed() { },
    mounted() {
      this.name = window.sessionStorage.getItem("account")
    },
    methods: {
      // 退出登录
      logout() {
        sessionStorage.clear();
        this.$router.push({ name: "login" });
      },
      update() {
        this.$emit("update",true)
        // this.$router.push("update");
      },
    },
  };
</script>
<style lang='scss' scoped>
  .header {
    /* position: fixed; */
    z-index: 999;
    left: 200px;
    top: 0;
    bottom: 0;
    right: 0;
    height: 40px;
    background-color: #7d89c3;
    /* border-left: 1px solid rgb(226, 245, 243); */
    border-bottom: 1px solid rgb(226, 245, 243);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  }

  /deep/.el-avatar {
    width: 30px;
    height: 30px;
  }

  /deep/.el-button--small.is-circle {
    padding: 0px;
    border: none;
  }

  /deep/.el-table td,
  .el-table th {
    text-align: center;
  }

  /deep/.el-table th {
    text-align: center;
    background: rgb(236, 235, 235);
  }

  @media (max-width:48rem) {
    .header{
      left: 0;
    }
  }
</style>