<template>
<div>
    <el-table :data="tableData" border style="width: 100%;margin-top: -1rem;">
        <el-table-column prop="child_name" label="学生姓名" min-width="10%">
        </el-table-column>
        <el-table-column prop="year" label="入学年份"  min-width="10%">
        </el-table-column>
        <el-table-column prop="class_name" label="班级名称"  min-width="10%">
        </el-table-column>
        <el-table-column prop="enter" label="入校状态" min-width="10%">
        </el-table-column>
        <el-table-column prop="enter_time" label="入校时间" min-width="15%">
        </el-table-column>
        <el-table-column prop="leave" label="出校状态" min-width="10%">
        </el-table-column>
		<el-table-column prop="leave_time" label="出校时间" min-width="15%">
		</el-table-column>
        <el-table-column label="接送详情" min-width="10%">
            <template slot-scope="scope">
                <el-button size="small" type="text" @click="(dialog=true),detail(scope.row)">详情</el-button>
            </template>
        </el-table-column>
    </el-table>
    

    <!-- 对话框 -->
    <el-dialog :visible.sync="dialog" width="700px" title="接送详情" style="height: 400px;" :close-on-click-modal="false">
        <!-- 分割线 -->
        <el-divider></el-divider>
        <div class=" ml-6 mr-6 mt-6 mb-4">
            <div style="width: 100%; margin: auto; " class="font-semibold text-sm">
                <el-divider direction="vertical" style="color: #7d89c3;width: 8px;"></el-divider>入校信息
            </div>
            <div class="ml-6 mr-6 mt-2 mb-8 flex">
                <div class="mr-10"><span>家长名称：{{info.parent_name}}</span></div>
                <div class="mr-10"><span>联系电话：{{info.parent_phone}}</span></div>
                <div class="mr-10"><span>入校时间：{{info.enter_time}}</span></div>
            </div>
            <div style="width: 100%; margin: auto; " class="font-semibold text-sm mt-8">
                <el-divider direction="vertical" style="color: #7d89c3;width: 8px;"></el-divider>离校信息
            </div>
            <div class="ml-6 mr-6 mt-2 flex">
                <div><span class="mr-10">家长名称：{{info.out_parent_name}}</span></div>
                <div><span class="mr-10">联系电话：{{info.out_parent_phone}}</span></div>
                <div><span class="mr-10">离校时间：{{info.leave_time}}</span></div>
            </div>
            <div class="mt-6"></div>
        </div>
    </el-dialog>
</div>
</template>

<script>
export default{
    props:{
        tableData:{
            type:Array
        }
    },
    data(){
        return{
            dialog:false,
            info:{

            }
        }
    },
    methods:{
        detail(row){
            console.log(row)
            this.info=row
        }
    },
    
}
</script>

<style lang="scss" scoped>

</style>