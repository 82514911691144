<template>
  <div id="lineFir" class="w-full h-96"></div>
</template>

<script>
export default {
  data() {
    return {
      series: [
        {
          name: "软件系",
          type: "line",
          stack: "总量",
          data: [120, 132, 101, 90, 230, 0, 0],
        },
        {
          name: "网络系",
          type: "line",
          stack: "总量",
          data: [220, 182, 191, 234, 290, 330, 310],
        },
        {
          name: "外语系",
          type: "line",
          stack: "总量",
          data: [150, 232, 201, 154, 190, 330, 410],
        },
        {
          name: "计算机系",
          type: "line",
          stack: "总量",
          data: [320, 332, 301, 334, 390, 330, 320],
        },
        {
          name: "数码媒体系",
          type: "line",
          stack: "总量",
          data: [820, 932, 901, 934, 1290, 1330, 1320],
        },
      ], //y轴数据
      xData: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"], //x轴数据
    };
  },
  mounted() {
    this.drawLine();
  },
  methods: {
    drawLine() {
      var chartDom = document.getElementById("lineFir");
      var myChart = this.$echarts.init(chartDom);
      var option = {
        title: {
          text: "自定义标题", //图表标题
          top: "1%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [
            ...this.series.map((e) => {
              return e.name;
            }),
          ], //折线名称["软件系", "网络系", "外语系", "计算机系", "数码媒体系"]
          top: "1%",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          //保存到图片
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            dataView: { readOnly: false },
            magicType: { type: ["line", "bar"] },
            restore: {},
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.xData, //X轴刻度线上的名称
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: false, //去掉y轴刻度线
          },
          axisTick: {
            show: false,
          },
        },
        series: [...this.series], //每条折线的数据和属性
      };
      myChart.setOption(option);
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
  },
};
</script>

<style>
</style>