<template>
	<div>
		<el-table :data="tableData" border style="width: 100%;margin-top: -1rem;">
			<el-table-column prop="child_name" label="学生姓名" min-width="10%">
			</el-table-column>
			<el-table-column prop="child_name" label="学生姓名" min-width="10%">
			</el-table-column>
			<el-table-column prop="class_name" label="班级名称" min-width="10%">
			</el-table-column>
			<el-table-column prop="create_time" label="审核时间" min-width="18%">
			</el-table-column>
			<el-table-column prop="update_time" label="确认时间" min-width="18%">
			</el-table-column>
			<el-table-column label="审核状态" min-width="10%">
				<template slot-scope="scope">
					<span v-if="scope.row.status=='已拒绝'" style="color: red;">{{scope.row.status}}</span>
					<span v-else-if="scope.row.status=='已审核'" style="color: green;">{{scope.row.status}}</span>
					<span v-else style="color: black;">{{scope.row.status}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="parent_name" label="家长姓名" min-width="10%">
			</el-table-column>
			<el-table-column prop="parent_phone" label="家长电话" min-width="15%">
			</el-table-column>
			<el-table-column label="审核详情" min-width="10%">
				<template slot-scope="scope">
					<el-button size="small" type="text" @click.native="detail(scope.row),(dialog=true)">审核</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 对话框 -->
		<el-dialog :visible.sync="dialog" width="700px" title="家长详情" :close-on-click-modal="false">
			<!-- 分割线 -->
			<el-divider></el-divider>
			<div class=" ml-6 mr-6 mt-6 mb-4" style="font-weight:bold">
				<div style="width: 100%; margin: auto; " class="font-semibold text-sm">
					<el-divider direction="vertical" style="color: #7d89c3;width: 8px;"></el-divider>孩子信息
				</div>
				<div class="ml-4 mr-2 mt-4 mb-8 flex ">
					<div class="mr-10"><span>孩子名称：{{child.child_name}}</span></div>
					<div class="mr-10" style=""><span>班级名称: {{child.class_name}}</span></div>
					<div class="mr-10"><span>申请时间：{{child.create_time}}</span></div>
				</div>
				<div class="ml-4 mr-2 mt-4 mb-8 flex ">
					<div class="mr-10"><span>家长姓名：{{child.parent_name}}</span></div>
					<div class="mr-10" style=""><span>家长电话：{{child.parent_phone}}</span></div>
					<div class="mr-10"><span>审核状态：{{child.status}}</span></div>
				</div>
				<div class="ml-4 mr-2 mt-4 mb-8 flex">
					<div class="mr-10"><span>申请理由：{{child.reason}}</span></div>
				</div>
				<div class="mt-4"></div>
				<el-divider></el-divider>
				<div style="width: 100%;justify-content: flex-end;" class="flex mt-4">
					<el-button type="primary" size="small" class="mr-8" @click="handle(2)">通过</el-button>
					<el-button type="danger" size="small" class="mr-4" @click="handle(1)">拒绝</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		props: {
			tableData: {
				type: Array
			}
		},
		data() {
			return {
				dialog: false,
				verify_id: '',
				child:{}
			}
		},
		methods: {
			async handle(e) {
				const res = await this.http.post("/admin/verify/examine", {
					verify_id: this.verify_id,
					status: e
				})
				if (res.code == 200) {
					this.dialog = false
					const loading = this.$loading({
						lock: true,
						text: 'Loading',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					setTimeout(() => {
						this.$message({
						message: '审核成功',
						type: 'success'
					})
						loading.close();
						this.$emit("getExamine",e++)
					}, 1500)
				}

			},
			detail(row) {
				this.verify_id = row.id
				this.child=row
			}
		}
	}
</script>

<style>
</style>