breadcrumb.vue


<template>
  <div class="breadcumb">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item  v-for="(item,index) in breadlist" v-if="index>=1" :key="index" :to="item.path">{{item.meta.title}}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>

  export default {
    name: 'Home',
    data() {
      return {
        lists: [],
        breadlist: []
      }
    },
    methods: {
      getBreadcrumb() {
        let matched = this.$route.matched;
        this.breadlist = matched
        console.log(matched)
      }
    },

    //监听路由变化
    watch: {
      $route(e) {
        this.getBreadcrumb()
        // console.log(e)
        // console.log(this.breadlist)
      }
      //  $route(to,from){
      //    let matched = to.matched;   //this.$route.matched
      //    //这个地方是为了固定第一级目录是首页不变
      //    if(matched.length && matched[0].name!=="page"){
      //      matched = [{ path: '/index/page',name: 'page',meta:{title:'导入管理'}},...matched];
      //    }
      // console.log(matched)
      //    // ...数组名 是一种追加数组的方法
      //    var arr = [1,2,3]
      //    var arr1 = [4,5]
      //    var arr2 = [2,...arr1] == [2,4,5]
      //    //
      //    this.lists = matched;
      //  }
    }
  }
</script>

<style scoped>
 /deep/ .el-breadcrumb__item:last-child .el-breadcrumb__inner,
  .el-breadcrumb__item:last-child .el-breadcrumb__inner a,
  .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
  .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
    color: white;
  }
</style>