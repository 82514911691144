import Vue from "vue"
import router from "./router"
import App from "./App.vue"
import store from "./store"
import ElementUI from "element-ui" //element组件库
import "element-ui/lib/theme-chalk/index.css"
import echarts from "echarts" //图表
import check from "./dist/util" //封装函数 如需自引
import http from "./dist/http"
import "tailwindcss/tailwind.css"
Vue.prototype.$echarts = echarts
Vue.prototype.check = check //封装函数，如需自引
Vue.prototype.http=http
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(http)

new Vue({
  render: (h) => h(App),

  router,
  store,
}).$mount("#app")
