<template>
  <div class="nav">
    <div class="openMenu">
      <h4 class="text-white flex align-center text-bold">幼儿园门禁系统</h4>
    </div>
    <div class="scorllBox">
      <el-scrollbar style="height: 100%">
        <el-menu
          :router="true"
          :default-active="this.$route.path"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          background-color="rgb(1, 30, 37)"
        >
          

          <el-menu-item
            v-for="(item, i) in navList"
            :key="i + 1000"
            :index="item.name"
            ><i :class="item.icon"></i>
            <span slot="title">{{ item.label }}</span>
          </el-menu-item>
        </el-menu>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [],
    };
  },
  computed: {},
  mounted() {},
  created() {
    let role = window.sessionStorage.getItem("role")
    console.log(role)
    if(role=="normal"){
      this.navList=[
        {
          name: "/index/park",
          label: "园区管理",
          icon: "el-icon-c-scale-to-original",
		  
          isAuthority: false,
        },
        {
          name: "/index/outInIndex",
          label: "校园出入记录",
          icon: "el-icon-notebook-2",
          isAuthority: false,
        },
        {
          name: "/index/examineIndex",
          label: "审核管理",
          icon: "el-icon-thumb",
          isAuthority: false,
        },
        {
          name:"/index/statistics",
          label:"数据统计",
          icon: "el-icon-document-copy",
          isAuthority:false
        }
      ]
    }else if(role=="super"){
      this.navList=[
        {
			  name:"/index/page",
			  label:"导入管理",
			  icon:'el-icon-position'
		  },
        {
          name: "/index/admin",
          label: "管理员管理",
          icon: "el-icon-user",
          isAuthority: false,
        },
        {
          name: "/index/allStatistic",
          label: "总数据统计",
          icon: "el-icon-user",
          isAuthority: false,
        }
        // {
        //   name:"/index/statistics",
        //   label:"数据统计",
        //   icon: "el-icon-s-custom",
        //   isAuthority:false
        // }
      ]
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  /* background-image: url("../assets/img/bg.png"); */
  /* background-size: cover; */
  
}

.openMenu {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  /* background-image: url("../assets/img/bg.png"); */
  background-size: cover;
  background-color: #7d89c3;
  // border-bottom: 1px solid rgb(97, 97, 100);
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.el-menu {
  width: 280px;
  height: calc(100vh - #{30px});
  background-image: url("../assets/img/bg.png");
}
.el-menu-item {
  color: white;
  background:#fff0 !important;
}
li.el-menu-item.is-active {
  color: #000000;
  border-right: 3px solid #049eff;
}
/deep/.el-submenu__title {
  color: white;
}
/deep/ .el-scrollbar__wrap {
  overflow: scroll;
  width: 100%; 
  height: 110%; 
  /* //隐藏侧边默认滚动条 （达不到效果可以自己试着微调） 如解决不了可尝试用 `margin-right -60px` */
  /* //隐藏底部滚动条  （100%如果达不到效果可以试着用110%） */
}
.scorllBox {
  height: 93vh;
}
</style>