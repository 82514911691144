<template>
    <div>
        <el-card class="mt-2 ml-4 mr-4 bg-white border-white">

            <div style="width: 100%; margin: auto; " class="font-semibold text-lg">
                <el-divider direction="vertical" style="color: #7d89c3;width: 5px;"></el-divider>审核管理
            </div>
            <!-- 查询框 -->
            <el-form :model="searchData" class="demo-form-inline mt-4 pl-6 pr-6" :label-width="labelWidth">
                <el-row class="flex">
                    <el-input class="mr-2" v-model="searchData.class" size="small" style="width: 8rem;"
                        placeholder="班级名称">
                    </el-input>
                    <el-input class="mr-2" v-model="searchData.name" size="small" style="width: 8rem;"
                        placeholder="学生姓名">
                    </el-input>
					<el-date-picker v-model="searchData.time" type="date" size="small"  placeholder="选择日期">
					</el-date-picker>
                    <el-button type="primary" plain size="small" style="background-color: #706fa8c3;color: white;margin-left: 1rem;"
                        icon="el-icon-search" @click="search">搜索</el-button>
                    <el-button type="primary" size="small" style="background-color: white;color: black;"
                        icon="el-icon-refresh" plain @click="reset">重置</el-button>
                    
                </el-row>
            </el-form>
            <!-- 表格 -->
            <el-tabs v-model="activeName" @tab-click="handleClick" class="mt-4 ml-4">
                <el-tab-pane :label="'全部 '+top.all" name="first">
                    <tableExamine :tableData="tableData" @getExamine="getExamine"></tableExamine>
                </el-tab-pane>
                <el-tab-pane :label="'未审核 '+top.wait" name="second">
                    <tableExamine :tableData="tableData" @getExamine="getExamine"></tableExamine>
                </el-tab-pane>
                <el-tab-pane :label="'已通过 '+top.accept" name="three">
                    <tableExamine :tableData="tableData" @getExamine="getExamine"></tableExamine>
                </el-tab-pane>
                <el-tab-pane :label="'已拒绝 '+top.refuse" name="four">
                    <tableExamine :tableData="tableData" @getExamine="getExamine"></tableExamine>
                </el-tab-pane>
            </el-tabs>
            <!-- <div class="flex justify-end text-xs mt-4">*该表格的已到状态会在当日的24:00后自动更新</div> -->
            <!-- 分页 -->
            <div class="flex  bg-white ml-4 mt-6 pt-4 pb-4">
                <div></div>
                <Pagination :currentPage="currentPage" :total="total" :pageSize="pageSize" @pageChange="pageChange" />
            </div>
        </el-card>
    </div>
</template>

<script>
    import Pagination from "../../../components/Pagination/pagination";
    import tableExamine from "../components/tableExamine.vue"
    export default {

        data() {
            return {
                form: {
                    imgUrl: "", //图片字段
                    platName: "", //自定义字段
                },
                activeName: 'first',
                labelWidth: "100px",
                searchData: {
                    name: '',
                    time:'',
                    class:''
                },
                top:{
                    all:'',
                    accept:'',
                    refuse:'',
                    wait:'',
                },
                tableData: [],
                options: [{
                    value: '2018',
                    label: '2018'
                }, {
                    value: '2019',
                    label: '2019'
                }, {
                    value: '2020',
                    label: '2020'
                }, {
                    value: '2021',
                    label: '2021'
                }, {
                    value: '2022',
                    label: '2022'
                }, {
                    value: '2023',
                    label: '2023'
                }, {
                    value: '2023',
                    label: '2023'
                }],
                // 对话框
                isAdd: false, //区分添加和编辑
                dialog: false,
                // 分页
                total: 0,
                currentPage: 1,
                pageSize:10,
                tabIndex:0,
                nursery_id:'',
                nowTime:'',
                examineKey:''
            };
        },

        components:{
            tableExamine,
            Pagination 
        },
        mounted() {
            this.nursery_id=window.sessionStorage.getItem("nursery_id")
            this.getList()
        },
        methods: {
            handleClick(tab) {
                this.tabIndex=tab.index
                this.getList()
            },
            getExamine(e){
                this.examineKey=e
                this.getList()
            },
			//重置
			reset(){
				this.searchData={}
				this.currentPage=1
				this.pageSize=10
				this.getList()
			},
            // 获取基本数据
            async getList() {
                this.nowDate()
                const RES = await this.http.post("/admin/verify/all", {
                    limit: this.pageSize,
                    page: this.currentPage,
                    nursery_id:this.nursery_id,
                    time:this.searchData.time == '' ? this.nowTime : this.searchData.time,
                    key:this.tabIndex,
                    name:this.searchData.name,
                    class:this.searchData.class
                });
                if (RES.code == 200) {
                    this.tableData = RES.data
                    this.total = RES.total
                    this.top.all=RES.all
                    this.top.refuse=RES.refuse
                    this.top.wait=RES.wait,
                    this.top.accept=RES.accept
                    console.log(this.top)
                }
            },
            nowDate() {
                //获取当前时间
                var date = new Date();
                var year = date.getFullYear();
                var month = date.getMonth() + 1;
                var day = date.getDate();
                if (month < 10) {
                    month = "0" + month;
                }
                if (day < 10) {
                    day = "0" + day;
                }
                this.nowTime = year + "-" + month + "-" + day;
            },
            //分页
            pageChange(index) {
                this.currentPage = index._currentPage;
                this.getList();
            },
			//查询
			search() {
			    this.currentPage = 1;
			    this.getList();
			},
        },
    };
</script>
<style lang="scss" scoped>
     /deep/.el-dialog__headerbtn .el-dialog__close {
        color: black;
    }

    /deep/.el-dialog {
        height: 350px;
    }

    /deep/.el-dialog__header {
        background-color: #9473ee;
        padding: 10px 10px 10px;
    }

    /deep/.el-tabs__header {
        background-color: #d9dceca3;
    }

    /deep/.el-dialog__headerbtn {
        top: 10px;
    }

    /deep/.el-dialog__title {
        color: white;
        font-size: 1rem;
    }

    /deep/.el-table th {
        background-color: white !important;
    }

    /deep/.el-tabs__nav {}

    /deep/.el-tabs__item.is-active {
        background-color: #d4caf1;
        color: #535c8d;
    }

    /deep/.el-tabs__active-bar {
        background-color: #535c8d;
    }

    /deep/.el-table td,
    .el-table th {
        text-align: center;
    }

    /deep/.el-tabs--top .el-tabs__item.is-top:nth-child(2){
        padding: 0 30px;
    }

    /deep/.el-table th {
        text-align: center;
        background: rgb(236, 235, 235);
    }

    /deep/ .avatar-uploader .el-upload {
        border: 1px dashed hsl(0, 0%, 85%);
        cursor: pointer;
        position: relative;
        overflow: hidden;
        background: rgb(204, 204, 204);
    }

    /deep/.avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }

    /deep/ .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 200px;
        height: 150px;
        line-height: 150px;
        text-align: center;
    }

    /deep/ .avatar {
        width: 200px;
        height: 150px;
        display: block;
    }

    /deep/.el-form-item {
        margin-bottom: 5px;
    }

    /deep/.el-dialog__body {
        padding: 0px;
    }

    /deep/.el-divider--horizontal {
        margin: 0px;
    }


    /deep/.el-tabs__header {
        background-color: #f8f8f8;
    }

      /deep/.el-tabs__item.is-active {
        background-color: #ecedf3;
		padding: 0 5px;
        color: #535c8d;
    }
    /deep/.el-dialog__header {
        background-color: #9473ee;
        padding: 10px 10px 10px;
    }
    /deep/.el-tabs__active-bar {
        background-color: #7d89c3;
        // margin-left: -1.8rem;
    }
    /deep/.el-button--primary.is-plain{
            border-color: inherit;
    }
</style>