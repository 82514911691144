<template>
	<div>
		<div ref="scroll">
			<div v-for="item in 40" :key="item">{{item}}</div>
			<img src="../assets/img/bg.png" />
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				clientHeight: '',
				scroll: '',
				num: 0,		
			}
		},
		mounted() {
		 this.scroll=window.addEventListener("scroll", this.handleScrollx, true);
		 console.log(this.scroll)
		},
		methods: {
			handleScrollx() {
				console.log('滚动高度', window.pageYOffset);
				console.log('距离顶部高度', this.$refs.scroll.getBoundingClientRect().top);
				console.log(document.documentElement.scrollTop || document.body.scrollTop)
			},
		},
		watch: {},
		//离开页面时一定要销毁不然会出问题
		beforeDestroy() {
			window.removeEventListener("scroll", this.handleScrollx)
		}
	}
</script>

<style>
</style>
