import axios from "axios"
import QS from "qs"
axios.defaults.baseURL = "/api"
axios.defaults.timeout = 300000
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8"
axios.interceptors.request.use(
  (config) => {
    var token = window.sessionStorage.getItem("token")
    config.headers.token = token
    return config
  },
  (error) => {
    return Promise.error(error)
  }
)
var uploadRequest = axios.create({
  baseURL: "/api",
  timeout: 300000,
  headers: { "Content-Type": "multipart/form-data" },
})

var http = {
  get(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          params: params,
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.data)
        })
    })
  },
  post(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, QS.stringify(params))
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.data)
        })
    })
  },
  upload(url, file) {
    return new Promise((resolve, reject) => {
      uploadRequest
        .post(url, file)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.data)
        })
    })
  },
  download(url) {
    let iframe = document.createElement("iframe")
    iframe.style.display = "none"
    iframe.src = url
    iframe.onload = function() {
      document.body.removeChild(iframe)
    }
    document.body.appendChild(iframe)
  },
}
export default http
