<template>
  <div class="index">
    <Nav ref="nav" class="nav"></Nav>
    <Header class="header" @update="update" ref="child" ></Header>
    <div id="content" class="content" >
      <router-view ></router-view>
    </div>
    <!-- 修改密码对话框 -->
    <el-dialog :visible.sync="dialog" width="550px" title="" :close-on-click-modal="false">
      <!-- 分割线 -->
      <el-divider style="margin-top: 0rem;"></el-divider>
      <div class=" ml-6 mr-6  mb-4">
        <el-descriptions class="margin-top" title="修改密码" :column="1" border>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              请输入原密码
            </template>
            <el-input placeholder="" v-model="password.old" show-password></el-input>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-mobile-phone"></i>
              请输入新密码
            </template>
            <el-input placeholder="" v-model="password.new" show-password></el-input>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-mobile-phone"></i>
              请确认新密码
            </template>
            <el-input placeholder="" v-model="password.newCofirm" show-password></el-input>
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="flex justify-center items-center">
        <el-button class="mr-10" type="info" @click="dialog=false">取消</el-button>
        <el-button type="primary" @click="passwordSubmit()">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import Nav from "@/components/Nav";
  import Header from "@/components/Header";
  export default {
    components: {
      Nav,
      Header,
    },
    data() {
      return {
        dialog: false,
        password: {
          new: '',
          old: '',
          newCofirm: '',
		  school_id:''
        }
      };
    },
    created() {
    },
    methods: {
      update(e) {
        this.dialog = true
      },
      //修改密码
      async passwordSubmit() {
        let old = this.$store.state.user.password
        if (this.password.new != this.password.newCofirm) {
          this.$message.error('两次输入的密码不相同，请重新输入');
        } else if (old != this.password.old) {
          this.$message.error('原密码输入错误，请重新输入');
        } else {
          const res = await this.http.post("admin/changePassword", {
            account: window.sessionStorage.getItem("account"),
            newpassword: this.password.new,
            admin_id: window.sessionStorage.getItem("accountId"),
			school_id:window.sessionStorage.getItem("school_id")
          })
          if (res.code == 200) {
            this.$message({
              message: '密码修改成功,跳转到登录页面',
              type: 'success'
            });
            this.$refs.child.logout()
          }
        }

      }
    },
  };
</script>

<style scoped>
  .index {
    background: white !important;
  }

  /* 导航栏样式 */
  .header {
    position: fixed !important;
  }

  /* router-view样式 */
  .content {
    position: relative;
    padding-top: 3rem;
    margin-left: 200px;
    background: white;
  }
  
  
  @media (max-width:48rem) {
    .content {
    margin-left: 0px;
    background-image: url('../assets/img/bg2.png') !important;
    width: 100%;
  }
  .nav{
    display: none;
  }
  .header{
    display: none;
  }
  .index{
    /* background: turquoise !important; */
    /* background-image: url('../assets/img/bg2.png') !important; */
  }
  }
</style>