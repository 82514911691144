<template>
  <div id="hisFir" class="h-96 w-6/12"></div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      xData: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"], //X周数据
      seriesData: [10, 52, 200, 334, 390, 330, 220], //y轴数据
    };
  },

  mounted() {
    this.drawLine();
  },

  methods: {
    drawLine() {
      var chartDom = document.getElementById("hisFir");
      var myChart = this.$echarts.init(chartDom);
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        title: {
          text: "访问量",
          top: "1%",
        },
        toolbox: {
          //保存到图片
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            dataView: { readOnly: false },
            magicType: { type: ["line", "bar"] },
            restore: {},
            saveAsImage: {},
          },
        },
        grid: {
          top: "15%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.xData,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: false, //去掉y轴刻度线
            },
            axisTick: {
              show: false,
            },
            //
            // splitLine: {
            //   show: false,   //去掉y轴分割线
            // },
          },
        ],
        series: [
          {
            name: "访问量",
            type: "bar",
            barWidth: "50%",
            data: this.seriesData,
            itemStyle: {
              //通常情况下：
              normal: {
                color: "#409eff",
              },
              //鼠标悬停时：
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      myChart.setOption(option);
      // 自适应
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
  },
};
</script>
<style lang='scss' scoped>
</style>