<template>
    <div>
        <el-card class="mt-2 ml-4 mr-4 bg-white border-white">
            <div style="width: 100%; margin: auto; " class="font-semibold text-lg">
                <el-divider direction="vertical" style="color: #7d89c3;width: 5px;"></el-divider>导入管理
            </div>
            <!-- 查询框 -->
            <el-form
                :model="searchData"
                class="demo-form-inline mt-4 pl-6 pr-6"
                :label-width="labelWidth"
            >
                <el-row class="flex">
                    <el-input
                        class="mr-2"
                        v-model="searchData.name"
                        size="small"
                        style="width: 12rem;"
                        placeholder="幼儿园名称"
                    ></el-input>
                    <el-button
                        type="primary"
                        plain
                        size="small"
                        style="background-color: #706fa8c3;color: white;"
                        icon="el-icon-search"
                        @click="search()"
                    >搜索</el-button>
                    <el-button
                        type="primary"
                        size="small"
                        style="background-color: white;color: black;"
                        icon="el-icon-refresh"
                        plain
                        @click="reset()"
                    >重置</el-button>
                    <el-button
                        type="primary"
                        size="small"
                        style="background-color: #000000a6;color: white;"
                        v-if="role == 'super'"
                        icon="el-icon-sort"
                        @click="(dialog = true), (form = {})"
                        plain
                    >导入</el-button>
                </el-row>
            </el-form>
            <!-- 表格 -->
            <el-tabs v-model="activeName" @tab-click="handleClick" class="mt-4 ml-4">
                <el-tab-pane :label="'全部' + tabTitle.all" name="first">
                    <tableExamine :tableData="tableData" ref="table"></tableExamine>
                </el-tab-pane>
                <el-tab-pane :label="'未导入' + tabTitle.empty" name="second">
                    <tableExamine :tableData="tableData" ref="table2"></tableExamine>
                </el-tab-pane>
                <el-tab-pane :label="'已完成' + tabTitle.finish" name="three">
                    <tableExamine :tableData="tableData" ref="table3"></tableExamine>
                </el-tab-pane>
                <el-tab-pane :label="'导入中' + tabTitle.run" name="four">
                    <tableExamine :tableData="tableData" ref="table4"></tableExamine>
                </el-tab-pane>
            </el-tabs>
            <!-- <div class="flex justify-end text-xs mt-4">*该表格的已到状态会在当日的24:00后自动更新</div> -->
            <!-- 分页 -->
            <div class="flex bg-white ml-4 mt-6 pt-4 pb-4">
                <div></div>
                <Pagination
                    :currentPage="currentPage"
                    :pageSize="pageSize"
                    :total="total"
                    @pageChange="pageChange"
                />
            </div>
        </el-card>

        <!-- 对话框 -->
        <el-dialog :visible.sync="dialog" width="700px" title="导入学校" :close-on-click-modal="false">
            <template v-if="Loading == 0">
                <!-- 分割线 -->
                <el-divider></el-divider>
                <div class="ml-6 mr-6 mt-6 mb-4">
                    <div style="width: 100%; margin: auto; " class="font-semibold text-sm">
                        <el-divider direction="vertical" style="color: #7d89c3;width: 8px;"></el-divider>填写信息
                    </div>
                    <div class="ml-4 mr-6 mt-2 mb-8 flex">
                        <el-form
                            :model="form"
                            class="demo-form-inline mt-4 pr-6"
                            :label-width="labelWidth"
                        >
                            <el-row>
                                <el-col :span="7" class="flex">
                                    <el-form-item label="aes_key :">
                                        <el-input
                                            size="small"
                                            v-model="form.aes_key"
                                            placeholder="请输入"
                                            style="width: 200px;"
                                        ></el-input>
                                    </el-form-item>
                                    <el-form-item label="Corpid :">
                                        <el-input
                                            size="small"
                                            v-model="form.corp_id"
                                            placeholder="请输入"
                                            style="width: 200px;"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="7" class="flex">
                                    <el-form-item label="Agentid :">
                                        <el-input
                                            size="small"
                                            v-model="form.agent_id"
                                            placeholder="请输入"
                                            style="width: 200px;"
                                        ></el-input>
                                    </el-form-item>
                                    <el-form-item label="Appkey :">
                                        <el-input
                                            size="small"
                                            v-model="form.app_key"
                                            placeholder="请输入"
                                            style="width: 200px;"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="7" class="flex">
                                    <el-form-item label="AppSecret :">
                                        <el-input
                                            size="small"
                                            v-model="form.app_secret"
                                            placeholder="请输入"
                                            style="width: 200px;"
                                        ></el-input>
                                    </el-form-item>
                                    <el-form-item label="签名token :">
                                        <el-input
                                            size="small"
                                            v-model="form.token"
                                            placeholder="请输入"
                                            style="width: 200px;"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="7" class="flex">
                                    <el-form-item label="account :">
                                        <el-input
                                            size="small"
                                            v-model="form.account"
                                            placeholder="请输入"
                                            style="width: 200px;"
                                        ></el-input>
                                    </el-form-item>
                                    <el-form-item label="password :">
                                        <el-input
                                            size="small"
                                            v-model="form.password"
                                            placeholder="请输入"
                                            style="width: 200px;"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="7" class="flex">
                                    <el-form-item label="集团名称 :">
                                        <el-input
                                            size="small"
                                            v-model="form.name"
                                            placeholder="请输入"
                                            style="width: 200px;"
                                        ></el-input>
                                    </el-form-item>
                                    <el-form-item label="地区 :">
                                        <el-select v-model="form.region_id" placeholder="请选择" size="small" style="width:200px" @change="areaChange">
                                            <el-option
                                                v-for="item in options"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                    <div class="mt-6">
                        <el-divider style="color: #7d89c3;width: 5px;"></el-divider>
                        <div class="flex justify-end mt-4">
                            <el-button
                                type="primary"
                               
                                class="mr-8"
                                @click="importData()"
                            >确定</el-button>
                            <el-button                              
                                class="mr-8"
                                @click="dialog = false"
                            >取消</el-button>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else-if="Loading == 1">
                <img src="../../assets/img/loading.gif" />
            </template>
            <template v-else-if="Loading == 2">
                <div style="margin-left: 35%;margin-top: 5rem;">
                    <div class="text-5xl font-bold text-blue-600">导入成功</div>
                    <el-button
                        type="info"
                        size="small"
                        class="mt-20 ml-2"
                        style="width: 200px;"
                        @click="(from = {}), (dialog = false)"
                    >返回导入管理</el-button>
                </div>
            </template>
            <template v-else>
                <div style="margin-left: 20%;margin-top: 5rem;">
                    <div class="text-5xl font-bold text-blue-600">导入失败请重新导入</div>
                    <el-button
                        type="primary"
                        size="small"
                        class="mt-20 mr-4"
                        style="width: 200px;"
                        @click="(from = {}), (Loading = 0)"
                    >尝试重新导入</el-button>
                    <el-button
                        type="info"
                        size="small"
                        class="mt-20 ml-10"
                        style="width: 200px;"
                        @click="(from = {}), (dialog = false), (Loading = 0)"
                    >返回导入管理</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import Pagination from "../../components/Pagination/pagination";
import tableExamine from "../index/components/tableImport.vue"
export default {
    data() {
        return {
            forms: {
                imgUrl: "", //图片字段
                platName: "", //自定义字段
            },
            activeName: 'first',
            labelWidth: "100px",
            searchData: {
                name: '',
                createTime: []
            },
            options:[],
            form: {
                name: '',
                token: '',
                app_key: '',
                app_secret: '',
                agent_id: '',
                corp_id: '',
                aes_key: '',
                account: '',
                password: '',
                region_id:''
            },
            tableData: [],
            // 对话框
            isAdd: false, //区分添加和编辑
            dialog: false,
            // 分页
            total: 0,
            currentPage: 1,
            pageSize: 10,
            tabIndex: 0,
            Loading: 0,
            //标题显示数量
            tabTitle: {
                all: 0,
                run: 0,
                empty: 0,
                finish: 0
            },
            role: '',
            school_id: ''
        };
    },
    computed: {},
    components: {
        tableExamine,
        Pagination
    },
    watch: {},
    async mounted() {
    },
    created() {
        this.role = window.sessionStorage.getItem("role")
        this.school_id = window.sessionStorage.getItem("school_id")
        this.getList()
        this.getArea()
    },
    methods: {
        areaChange(e){
            console.log(e)
        },
        //获取区县
        async getArea(){
            const res = await this.http.post("admin/impschool/regionList")
            this.options=res.data
        },
        //幼儿园集团列表
        async getList() {
            const res = await this.http.post("admin/impschool/list", {
                limit: this.pageSize,
                page: this.currentPage,
                key: this.tabIndex,
                role: this.role,
                school_id: this.school_id == "undefined" ? "" : this.school_id
            })
            this.total = res.total
            this.tableData = res.data
            this.tabTitle.all = res.all
            this.tabTitle.run = res.run
            this.tabTitle.empty = res.empty
            this.tabTitle.finish = res.finish
        },
        handleClick(tab, event) {
            this.tabIndex = tab.index
            this.currentPage = 1
            if (this.searchData.name != '') {
                this.commonData()
            } else {
                this.getList()
            }
        },
        //重置
        reset() {
            this.searchData.name = ""
            this.pageSize = 10
            this.currentPage = 1
            this.tabIndex = 0
            this.activeName = "first"
            this.getList()
        },
        //导入
        async importData() {
            var that = this
            if (this.check.checkHasEmpty(this.form)) {
                this.$message({
                    type: 'warning',
                    message: '必须全部填写'
                })
            } else {
                this.Loading = 1
                const res = await this.http.post("admin/impschool/import", this.form)
                if (res.code == 200) {
                    this.Loading = 2
                } else {
                    this.$message({
                        type: 'warning',
                        message: res.msg
                    })
                    this.Loading = 3
                }
                this.getList()
            }
        },
        // 编辑
        handleEdit(row) {
            this.form = JSON.parse(JSON.stringify(row)); //编辑给表单赋值的时候，需要把数据深度拷贝
        },
        //分页
        pageChange(index) {
            this.currentPage = index._currentPage
            this.pageSize = index._pageSize
            if (this.check.checkEmpty(this.searchData)) {
                //检查查询字段是否都为空，都为空则表示普通分页，不都为空则表示分页查询
                this.getList();
            } else {
                this.commonData();
            }
        },
        // 确认
        handleConfirm() {
            var a = { imgUrl: this.forms.imgUrl, platName: this.forms.platName }; //检查必填字段
            if (this.check.checkHasEmpty(a)) {
                //调用函数checkHasEmpty() 检查必填字段是否有为空的
                this.$message("请将数据填写完整");
            } else {
                this.addImg();
            }
        },
        //查询
        search() {
            this.currentPage = 1;
            this.tabIndex = 0
            this.activeName = "first"
            this.commonData();
        },
        // 分页&&查询通用数据 分页查询的情况比较多 ，坑比较多 ，建议和我写成一样或者自我优化
        async commonData() {
            if (this.searchData.createTime == null) {
                this.searchData.createTime = [];
            }
            const RES = await this.http.post("admin/impschool/search", {
                limit: this.pageSize,
                page: this.currentPage,
                name: this.searchData.name,
                key: this.tabIndex,
                role: this.role
            });
            if (RES.code == 200 || RES.code == 204) {
                this.tableData = RES.data;
                this.total = RES.total;
                this.tabTitle.all = RES.all
                this.tabTitle.run = RES.run
                this.tabTitle.empty = RES.empty
                this.tabTitle.finish = RES.finish
            }
            if (RES.code == 400) {
                this.$message({
                    message: '暂无数据，请重新选择查询条件',
                    type: 'warning'
                });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/.el-dialog__headerbtn .el-dialog__close {
    color: black;
}

/deep/.el-dialog {
    height: 450px;
}

/deep/.el-dialog__header {
    background-color: #7d89c3;
    padding: 10px 10px 10px;
}

/deep/.el-tabs__header {
    background-color: #d9dceca3;
}

/deep/.el-dialog__headerbtn {
    top: 10px;
}

/deep/.el-dialog__title {
    color: white;
    font-size: 1rem;
}

/deep/.el-table th {
    background-color: white !important;
}

/deep/.el-tabs__nav {
}

/deep/.el-tabs__item.is-active {
    background-color: #d4caf1;
    color: #535c8d;
}

/deep/.el-tabs__active-bar {
    background-color: #535c8d;
}

/deep/.el-table td,
.el-table th {
    text-align: center;
}

/deep/.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
    padding-left: 20px;
}

/deep/.el-table th {
    text-align: center;
    background: rgb(236, 235, 235);
}

/deep/ .avatar-uploader .el-upload {
    border: 1px dashed hsl(0, 0%, 85%);
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background: rgb(204, 204, 204);
}

/deep/.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

/deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 200px;
    height: 150px;
    line-height: 150px;
    text-align: center;
}

/deep/ .avatar {
    width: 200px;
    height: 150px;
    display: block;
}

/deep/.el-form-item {
    margin-bottom: 5px;
}

/deep/.el-dialog__body {
    padding: 0px;
}

/deep/.el-divider--horizontal {
    margin: 0px;
}

/deep/.el-tabs__header {
    background-color: #f8f8f8;
}

/deep/.el-tabs__item.is-active {
    background-color: #ecedf3;
	padding: 0 5px;
    color: #535c8d;
}
/deep/.el-dialog__header {
    background-color: #7d89c3;
    padding: 10px 10px 10px;
}
/deep/.el-tabs__active-bar {
    background-color: #7d89c3;
    // margin-left: -1.8rem;
}
/deep/.el-button--primary.is-plain {
    border-color: inherit;
}

/deep/ .el-dialog__title {
    font-weight: bold;
}
/deep/.el-icon-close:before {
    color: white;
}
</style>