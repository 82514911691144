<template>
  <div class="bg">
    <div
      style="width: 400px; height: 400px; margin: 0 auto; margin-top: 10%;background-color: #e5e5e5;padding: 0 -2rem;border-radius: 20px;"
      class="text-center ">
      <div class="flex items-center justify-center">
        <span style="font-size: 30px; font-weight: bolder;color: #6c81bc;padding-top: 2rem;">幼儿园门禁管理后台</span>
      </div>
      <div style="margin-top: 30px">
        <el-input style="width: 350px" prefix-icon="el-icon-user" placeholder="账户" v-model="form.account"></el-input>
      </div>
      <div style="margin-top: 20px">
        <el-input style="width: 350px" prefix-icon="el-icon-lock" placeholder="密码" v-model="form.password"
          @keyup.enter.native="login()" type="password"></el-input>
      </div>
      <div class="justify-center flex" style="margin-top: 20px;margin-right: 16rem;">
        <el-checkbox v-model="form.checked" style="">记住密码</el-checkbox>
      </div>
      <div>
        <el-button type="primary" style="width: 350px; margin-top: 20px;background-color: #7285bc;" @click="login()">登录
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        form: {
          account: "",
          password: "",
          checked: true,
          name: "",
          imgUrl: "",
          api:''
        },
      };
    },
    methods: {
      login() {
        // if(this.form.account=="admin"&&this.form.password=="admin"){
        //   window.sessionStorage.setItem("isLogin", 1); //  给路由防卫传递信息 判断用户是否登录
        // this.$router.push({
        //   path: "/index/page",
        // });
        // }else{
        //   this.$message.error("账号或者密码错误")
        // }


        // 完整登录流程在下面 在开发中请用如下代码
        this.http.post(this.api, {
          account: this.form.account,
          password: this.form.password,
        }).then((res) => {
          if (res.code == 200) {
            // console.log(res)
            // 自动登录
            if (this.form.checked) {
              localStorage.setItem("form", JSON.stringify(this.form)); //如果勾选自动登录 则将数据存到缓存中
            } else {
              localStorage.removeItem("form"); // 如果取消自动登录 则去除缓存中的数据
            }
            window.sessionStorage.setItem("isLogin", 1); //  给路由防卫传递信息 判断用户是否登录
            window.sessionStorage.setItem("token", res.data.token)
            window.sessionStorage.setItem("role", res.data.role)
            window.sessionStorage.setItem("school_id", res.data.school_id)
            window.sessionStorage.setItem("account", res.data.account)
            window.sessionStorage.setItem("accountId", res.data.id)
            this.form = res.data;

            this.$store.commit("setUser", this.form); //可前往store.js增删改自己所需要的字段
            //判断如果是超级管理员登录后就跳转到空白页面,如果是普通用户就跳转到数据统计页面
            if (res.data.role == 'normal') {
              this.$router.push({
                path: "/index/statistics",
              });
            } else {
              this.$router.push({ path: '/index/allStatistic' })
            }
          } else {
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        });
      },
    },

    mounted() {
      if (localStorage.getItem("form")) {
        this.form.account = JSON.parse(localStorage.getItem("form")).account;
        this.form.password = JSON.parse(localStorage.getItem("form")).password;
      }
      if (this.$route.query.a != '' && this.$route.query.a != undefined & this.$route.query.p != '' && this.$route.query.p != undefined) {
        this.api="admin/linkValid"
        this.form.account = this.$route.query.a.replace(/\s/g, "+");//替换掉因为地址栏的加号转换成的空格，把空格转换成+号
        this.form.password = this.$route.query.p.replace(/\s/g, "+");
        this.login()
      }else{
        this.api="/admin/login"
      }
    },
  };
</script>

<style lang="scss" scoped>
  .bg {
    background-image: url("../assets/img/bg.png");
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid white;
  }
</style>