<template>
    <div>
        <el-table :data="tableData" border style="width: 100%;margin-top: -1rem;">
            <el-table-column prop="id" label="" min-width="5%">
            </el-table-column>
            <el-table-column prop="name" label="幼儿园名称" min-width="20%">
            </el-table-column>
            <el-table-column prop="count" label="园区数" min-width="10%">
            </el-table-column>
            <el-table-column prop="region" label="区县" min-width="10%">
            </el-table-column>
            <el-table-column prop="state" label="园区导入状态" min-width="10%">
            </el-table-column>
            <el-table-column prop="update_time" label="更新时间" min-width="15%">
            </el-table-column>
            <!-- <el-table-column prop="status" label="更新幼儿园名称" width="150">
                <template slot-scope="scope">
                    <el-button size="small" type="text">更新</el-button>
                </template>
            </el-table-column> -->
            <el-table-column label="园区详情" min-width="10%">
                <template slot-scope="scope">
                    <el-button size="small" type="text" @click="detail(scope.row)">详情</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    export default {
        props: {
            tableData: {
                type: Array
            }
        },
        data() {
            return {

            }
        },
        methods: {
            detail(row) {
                localStorage.setItem("kingId", row.id)
                localStorage.setItem("state", row.state)
                localStorage.setItem("kindName",row.name)
                if (row.state == "导入中") {
                    this.$confirm("数据有人在导入中，多人操作可能会造成数据混乱，是否进入", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(async () => {
                    this.$router.push({ name: "newPage" })
					})
					.catch(() => {
					});
                } else { 
                    this.$router.push({ name: "newPage" })
                }

            }
        },

    }
</script>

<style lang="scss" scoped>

</style>