<template>
  <div>
    <div class="bg-white pt-4 pb-4 pl-5 text-xl font-bold text-black">
      管理员管理
    </div>
    <el-card class="mr-4 ml-4 mt-4">
      <el-form
        :model="searchData"
        class="demo-form-inline mt-4 pl-6 pr-6"
        :label-width="labelWidth"
      >
        <!-- row-1 -->
        <el-row>
          <el-col :span="7">
            <el-form-item label="管理员名称 :"
              ><el-input v-model="searchData.name" size="small"></el-input
            ></el-form-item>
          </el-col>
          <el-col :offset="1" :span="7">
            <el-form-item label="管理员账号 :"
              ><el-input v-model="searchData.account" size="small"></el-input
            ></el-form-item>
          </el-col>
          <el-col :offset="1" :span="7">
            <el-form-item label="联系电话 :"
              ><el-input v-model="searchData.tel" size="small"></el-input
            ></el-form-item>
          </el-col>
        </el-row>

        <!-- row-2 -->
        <el-row class="mt-4">
          <el-col :span="7">
            <el-form-item label="等级 :"
              ><el-select size="small" class="w-full" v-model="searchData.level"
                ><el-option
                  v-for="(item, index) in levelOpt"
                  :key="index"
                  :label="item"
                  :value="item"
                ></el-option></el-select
            ></el-form-item>
          </el-col>
          <el-col :span="7" :offset="1">
            <el-form-item label="创建时间 :">
              <el-date-picker
                v-model="searchData.createTime"
                type="daterange"
                range-separator="一"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                size="mini"
                style="width: 100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="7">
            <div class="float-right">
              <el-button size="small" @click="search()">查询</el-button
              ><el-button
                type="primary"
                size="small"
                @click="(isAdd = true), (dialog = true), handleAdd()"
                >+ 新增</el-button
              >
            </div>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

    <el-card class="mr-4 ml-4 mt-4">
      <el-table :data="tableData" height="70vh">
        <el-table-column prop="id" label="序号"></el-table-column>
        <el-table-column prop="avatar" label="头像">
          <template slot-scope="scope">
            <el-image
              :src="scope.row.avatar"
              class="h-28 w-28"
              fit="fit"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="account" label="账号"></el-table-column>
        <el-table-column prop="tel" label="联系电话"></el-table-column>
        <el-table-column prop="level" label="等级"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="(isAdd = false), handleEdit(scope.row), (dialog = true)"
              ><span>编辑</span></el-button
            >
            <el-button type="text" @click="handleDel(scope.row)"
              ><span class="text-red-500">删除</span></el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 对话框 -->
    <el-dialog :visible.sync="dialog" width="600px">
      <div class="flex">
        <div class>
          <el-upload
            class="avatar-uploader"
            ref="upload"
            action="#"
            :show-file-list="false"
            :multiple="false"
            :auto-upload="false"
            :on-change="imgSaveToUrl"
          >
            <img v-if="form.avatar" :src="form.avatar" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>

        <div>
          <el-form :label-width="labelWidth" :model="form">
            <el-form-item label="名称 :"
              ><el-input v-model="form.name" size="small"></el-input
            ></el-form-item>
            <el-form-item label="账号 :"
              ><el-input v-model="form.account" size="small"></el-input
            ></el-form-item>
            <el-form-item label="密码 :"
              ><el-input v-model="form.password" size="small"></el-input
            ></el-form-item>
            <el-form-item label="联系电话 :"
              ><el-input v-model="form.tel" size="small"></el-input
            ></el-form-item>
            <el-form-item label="等级 :"
              ><el-select v-model="form.level" size="small"
                ><el-option
                  v-for="(item, index) in levelOpt"
                  :label="item"
                  :value="item"
                  :key="index"
                ></el-option></el-select
            ></el-form-item>
          </el-form>
        </div>
      </div>
      <div class="mt-5 text-center">
        <el-button size="small" class="mr-40" @click="dialog = false"
          >取消</el-button
        >
        <el-button type="primary" size="small" @click="handleConfirm()"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <!-- 分页 -->
    <div class="flex justify-between bg-white ml-4 mr-4 pt-4 pb-4">
      <div></div>
      <Pagination
        :currentPage="currentPage"
        :total="total"
        @pageChange="pageChange"
      />
    </div>
  </div>
</template>

<script>
import Pagination from "../../components/Pagination/pagination";
export default {
  components: { Pagination },
  data() {
    return {
      tableData: [{ name: "第一条数据为展示数据" }], //table数据
      searchData: {
        //搜索字段,根据自己需求 可自定义
        account: "",
        name: "",
        tel: "",
        level: "",
      },
      form: {
        //对话框数据,根据自己需求 可自定义
        account: "",
        name: "",
        tel: "",
        level: "",
        avatar: "",
        password: "",
        createTime: "",
      },
      labelWidth: "100px", //el-form-item的label宽度
      levelOpt: ["一级管理员", "二级管理员", "三级管理员"],
      isAdd: false, //区分添加和编辑
      dialog: false,
      // 分页
      limit: 10,
      currentPage: 1,
      total: 0,
    };
  },

  mounted() {
    // this.getList();
  },

  methods: {
    // 获取基本数据
    async getList() {
      const RES = await this.http.post("/XXXX/getXXXX", {
        limit: this.limit,
        page: this.currentPage,
      });
      if (RES.code == 1) {
        this.tableData = RES.data.data;
        this.total = RES.data.total;
      }
    },
    // 图片上传
    async imgSaveToUrl(event) {
      if (this.beforeUploadPicture(event.raw)) {
        var formData = new FormData();
        formData.append("file", event.raw);

        this.http.upload("/user/uploadFile", formData).then((res) => {
          //xx为上传的路径
          if (res.code == 1) {
            this.form.avatar = res.data;
            this.$message.success("上传成功");
          }
        });
      }
    },
    // 图片上传之前
    beforeUploadPicture(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isJPG && !isPNG) {
        this.$message.error("上传图片只能是 JPG 或者 PNG 格式!");
      }
      if (isJPG && isPNG) {
        if (!isLt10M) {
          this.$message.error("上传图片大小不能超过 10MB!");
        }
      }
      return (isJPG || isPNG) && isLt10M;
    },

    // 新增
    handleAdd() {
      this.form = { avatar: "" }; //添加管理员时，需要把form里面的数据清空，然后单独给头像的字段清理为空，防止图片不回显
    },

    // 编辑
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row)); //赋值需要深度拷贝，别问，问了就是给你一巴掌
    },

    // 确定
    async handleConfirm() {
      if (this.searchData.createTime == null) {
        this.searchData.createTime = [];
      } //如果时间选择器为null 需重新赋值为数组 , 防止后续参数判断错误
      var a = {
        //检查必填字段
        account: this.form.account,
        name: this.form.name,
        tel: this.form.tel,
        level: this.form.level,
        avatar: this.form.avatar,
        password: this.form.password,
        createTime: this.form.createTime,
      };
      if (this.check.checkHasEmpty(a)) {
        //调用函数checkHasEmpty() 检查必填字段是否有为空的
        this.$message("请将数据填写完整");
      } else {
        this.addAdmin();
      }
    },
    async addAdmin() {
      const RES = await this.http.post(
        this.isAdd ? "/admin/addAdmin" : "/admin/updAdmin",
        {
          id: this.isAdd ? "" : this.form.id,
          account: this.form.account,
          name: this.form.name,
          tel: this.form.tel,
          level: this.form.level,
          avatar: this.form.avatar,
          password: this.form.password,
          date1: this.searchData.createTime[0]
            ? this.searchData.createTime[0]
            : "",
          date2: this.searchData.createTime[1]
            ? this.searchData.createTime[1]
            : "",
        }
      );
      if (RES.code == 1) {
        this.$message.success(this.isAdd ? "添加成功" : "修改成功");
        this.getList();
        this.dialog = false;
      }
    },

    // 删除
    async handleDel(row) {
      this.$confirm("是否删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const RES = await this.http.post("/admin/delAdmin", {
            id: row.id,
          });
          if (RES.code == 1) {
            this.getList();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 分页&&查询通用数据 分页查询的情况比较多 ，坑比较多 ，建议和我写成一样或者自我优化
    async commonData() {
      if (this.searchData.createTime == null) {
        this.searchData.createTime = [];
      }
      const RES = await this.http.post("/XXXX/search", {
        name: this.searchData.name,
        account: this.searchData.account,
        tel: this.searchData.tel,
        level: this.searchData.level,
        limit: 10,
        page: this.currentPage,
        date1: this.searchData.createTime[0]
          ? this.searchData.createTime[0]
          : "",
        date2: this.searchData.createTime[1]
          ? this.searchData.createTime[1]
          : "",
      });
      if (RES.code == 1) {
        this.tableData = RES.data.data;
        this.total = RES.data.total;
      }
    },
    //查询
    search() {
      this.currentPage = 1;
      this.commonData();
    },
    //分页
    pageChange(index) {
      this.currentPage = index._currentPage;
      if (this.check.checkEmpty(this.searchData)) {
        //检查查询字段是否都为空，都为空则表示普通分页，不都为空则表示分页查询
        this.getList();
      } else {
        this.commonData();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .avatar-uploader .el-upload {
  border: 1px dashed hsl(0, 0%, 85%);
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: rgb(204, 204, 204);
}
/deep/.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px; //上传图片的宽
  height: 200px; //上传图片的高
  line-height: 200px;
  text-align: center;
}
/deep/ .avatar {
  width: 200px; //图片回显的宽
  height: 200px; //图片回显的高
  display: block;
}
/deep/.el-form-item {
  margin-bottom: 1px;
}

/deep/.el-table td,
.el-table th {
  text-align: center;
}
/deep/.el-table th {
  text-align: center;
  background: rgb(236, 235, 235);
}
/deep/.el-button--primary.is-plain{
            border-color: inherit;
    }
</style>
