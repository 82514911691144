<template>
  <div>
    <el-card class="ml-6 mt-5 mr-6"> <lineFir /></el-card>
    <el-card class="ml-6 mt-5 mr-6"><hisFir /></el-card>
  </div>
</template>

<script>
import lineFir from "./component/lineFir.vue";
import hisFir from "./component/hisFir.vue";
import wangedit from "../../components/wangEdit/wangEdit.vue";
export default {
  components: { lineFir, hisFir, wangedit },
  data() {
    return {
      content: "",
      dialog: false,
    };
  },

  mounted() {},
  methods: {},
};
</script>

<style>
</style>