<template>
  <div>
    <el-card class="mt-2 ml-4 mr-4 bg-white border-white">

      <div style="width: 100%; margin: auto; " class="font-semibold text-lg">
        <el-divider direction="vertical" style="color: #7d89c3;width: 5px;"></el-divider>学生管理
      </div>
      <!-- 查询框 -->
      <el-form :model="searchData" class="demo-form-inline mt-4 pl-6 pr-6" :label-width="labelWidth">
        <el-row class="flex">
          <el-input class="mr-2" v-model="searchData.name" size="small" style="width: 11rem;" placeholder="班级名称">
          </el-input>
          <el-input class="mr-2" v-model="searchData.name" size="small" style="width: 11rem;" placeholder="学号">
          </el-input>
          <el-input class="mr-2" v-model="searchData.name" size="small" style="width: 11rem;" placeholder="学生姓名">
          </el-input>
          <el-input class="mr-2" v-model="searchData.name" size="small" style="width: 11rem;" placeholder="家长联系电话">
          </el-input>
          <el-button type="primary" plain size="small" style="background-color: #706fa8c3;color: white;"
            icon="el-icon-search">搜索</el-button>
          <el-button type="primary" size="small" style="background-color: white;color: black;" icon="el-icon-refresh"
            plain>重置</el-button>
          <el-button type="primary" size="small" style="background-color: #000000a6;color: white;" icon="el-icon-sort"
            plain>导入</el-button>
        </el-row>
      </el-form>
      <!-- 表格 -->
      <el-tabs v-model="activeName" @tab-click="handleClick" class="mt-4 ml-4">
        <el-tab-pane label="全部" name="first">
          <el-table :data="tableData" border style="width: 100%;margin-top: -1rem;">
            <el-table-column prop="id" label="" width="50">
            </el-table-column>
            <el-table-column prop="school" label="幼儿园" width="200">
            </el-table-column>
            <el-table-column prop="date" label="入学年份" width="120">
            </el-table-column>
            <el-table-column prop="class" label="班级名称" width="120">
            </el-table-column>
            <el-table-column prop="sno" label="学号" width="150">
            </el-table-column>
            <el-table-column prop="sname" label="学生姓名" >
            </el-table-column>
            <el-table-column prop="pname" label="家长姓名" width="80">
            </el-table-column>
            <el-table-column prop="phone" label="家长联系电话" width="180">
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
       <!-- 分页 -->
    <div class="flex  bg-white ml-4 mt-6 pt-4 pb-4">
      <div></div>
      <Pagination :currentPage="currentPage" :total="total" @pageChange="pageChange" />
    </div>
    </el-card>

    <!-- 对话框 -->
    <el-dialog :visible.sync="dialog" width="700px"  title="家长详情" :close-on-click-modal="false">
      <!-- 分割线 -->
      <el-divider></el-divider>
      <div class=" ml-6 mr-6 mt-6 mb-4">
        <div style="width: 100%; margin: auto; " class="font-semibold text-sm">
          <el-divider direction="vertical" style="color: #7d89c3;width: 8px;"></el-divider>孩子信息
        </div>
        <div class="ml-6 mr-6 mt-2 mb-8 flex">
          <div class="mr-10"><span>孩子名称：张五</span></div>
          <div class="mr-10"><span>学校名称：浙江第一幼儿园</span></div>
          <div class="mr-10"><span>班级名称：大班1班</span></div>
        </div>
        <div style="width: 100%; margin: auto; " class="font-semibold text-sm mt-8">
          <el-divider direction="vertical" style="color: #7d89c3;width: 8px;"></el-divider>家长信息
        </div>
        <div class="ml-6 mr-6 mt-2 ">
          <div><span class="mr-10">家长名称：张五</span><span>联系电话：123456789</span></div>
          <div><span class="mr-10">家长名称：张五</span><span>联系电话：123456789</span></div>
          <div><span class="mr-10">家长名称：张五</span><span>联系电话：123456789</span></div>
        </div>
        <div class="mt-6"></div>
      </div>
    </el-dialog>

   
  </div>
</template>

<script>
  import Pagination from "../../components/Pagination/pagination";
  export default {
    components: { Pagination },
    data() {
      return {
        form: {
          imgUrl: "", //图片字段
          platName: "", //自定义字段
        },
        activeName: 'first',
        labelWidth: "100px",
        searchData: {
          name: ''
        },
        tableData: [{
          id:1,
          school:'浙江第一幼儿园',
          date:2019,
          class:'大班1班',
          sno:123456789,
          sname:'张三',
          pname:'张武',
          phone:'12345678911'
        },{
          id:1,
          school:'浙江第一幼儿园',
          date:2019,
          class:'大班1班',
          sno:123456789,
          sname:'张三',
          pname:'张武',
          phone:'12345678911'
        }],
        // 对话框
        isAdd: false, //区分添加和编辑
        dialog: false,
        // 分页
        total: 0,
        currentPage: 1,
      };
    },

    computed: {},

    watch: {},
    mounted() {
      // this.getList();  //一开始获取数据
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
      // 获取基本数据
      async getList() {
        const RES = await this.http.post("/XXXX/getXXXX", {
          limit: 10,
          page: this.currentPage,
        });
        if (RES.code == 1) {
          // this.tableData = RES.data.data;
          // this.total = RES.data.total;
        }
      },
      // 编辑
      handleEdit(row) {
        this.form = JSON.parse(JSON.stringify(row)); //编辑给表单赋值的时候，需要把数据深度拷贝
      },
      //分页
      pageChange(index) {
        this.currentPage = index._currentPage;
        this.getList();
      },
      // 确认
      handleConfirm() {
        var a = { imgUrl: this.form.imgUrl, platName: this.form.platName }; //检查必填字段
        if (this.check.checkHasEmpty(a)) {
          //调用函数checkHasEmpty() 检查必填字段是否有为空的
          this.$message("请将数据填写完整");
        } else {
          this.addImg();
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  /deep/.el-dialog__headerbtn .el-dialog__close{
    color: black;
  }
  /deep/.el-dialog{
    height: 300px;
  }
  /deep/.el-dialog__header{
    background-color: #9473ee;
    padding: 10px 10px 10px;
  }
  /deep/.el-dialog__headerbtn{
    top: 10px;
  }
  /deep/.el-tabs__header{
      background-color: #c8cff1;
  }
  /deep/.el-dialog__title{
    color: white;
    font-size: 1rem;
  }
  /deep/.el-table th{
    background-color: white !important;
  }
  /deep/.el-tabs__nav {
    background-color: #c8cff1;
    padding:0 10px;
  }

  /deep/.el-tabs__item.is-active {
    color: #535c8d;
  }

  /deep/.el-tabs__active-bar {
    background-color: #535c8d;
    margin-left: 10px;
  }

  /deep/.el-table td,
  .el-table th {
    text-align: center;
  }

  /deep/.el-table th {
    text-align: center;
    background: rgb(236, 235, 235);
  }

  /deep/ .avatar-uploader .el-upload {
    border: 1px dashed hsl(0, 0%, 85%);
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background: rgb(204, 204, 204);
  }

  /deep/.avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  /deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 200px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }

  /deep/ .avatar {
    width: 200px;
    height: 150px;
    display: block;
  }

  /deep/.el-form-item {
    margin-bottom: 5px;
  }

  /deep/.el-dialog__body {
    padding: 0px;
  }

  /deep/.el-divider--horizontal {
    margin: 0px;
  }
  /deep/.el-button--primary.is-plain{
            border-color: inherit;
    }
</style>