<template>
  <div>
    <div class="bg-white pt-4 pb-4 pl-5 text-xl font-bold text-black">
      用户管理
    </div>

    <!-- 查询 -->
    <el-form :model="searchData" class="demo-form-inline mt-4 pl-6 pr-6">
      <div class="bg-white pt-4">
        <el-row>
          <el-col :span="6">
            <el-form-item
              :label-width="formWidth"
              label="账号 :"
              class="text-xl"
            >
              <el-input
                class="w-full"
                size="small"
                v-model="searchData.id"
                placeholder="账号 "
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="0" :span="6">
            <el-form-item label="用户昵称 :" :label-width="formWidth">
              <el-input size="small" v-model="searchData.nickName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="0" :span="6">
            <el-form-item label="联系电话 :" :label-width="formWidth">
              <el-input v-model="searchData.tel" size="small"></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="0" :span="5">
            <el-form-item label="状态 :" :label-width="formWidth">
              <el-select
                v-model="searchData.status"
                size="small"
                class="w-full"
                clearable
                ><el-option
                  v-for="(item, index) in statusOpt"
                  :key="index"
                  :value="item"
                  :label="item"
                ></el-option
              ></el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row class="mt-2 pb-4">
          <el-col :offset="18" :span="5">
            <div class="float-right">
              <el-button size="small" @click="reset()">重 置</el-button>
              <el-button size="small" type="primary" @click="search()"
                >查 询</el-button
              >
            </div>
          </el-col>
        </el-row>
      </div>
      <el-form-item> </el-form-item>
    </el-form>
    <!-- 表单 -->
    <div class="pl-6 pr-6 text-xl">
      <div class="bg-white">
        <!-- 表单 -->
        <el-table
          :data="tableData"
          style="width: 98%; margin: 0 auto"
          class="pt-4"
          height="65vh"
        >
          <el-table-column prop="id" label="编号"></el-table-column>
          <el-table-column label="头像">
            <template slot-scope="scope">
              <el-image
                :src="scope.row.avatarUrl"
                class="w-32 h-32"
                fit="fit"
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="nickName" label="用户名称"></el-table-column>
          <el-table-column prop="tel" label="联系电话"></el-table-column>
          <el-table-column prop="status" label="状态"></el-table-column>
          <el-table-column prop="createTime" label="创建时间"></el-table-column>
          <el-table-column prop="" label="操作">
            <template slot-scope="scope">
              <button type="text" @click="handleDetail(scope.row)">
                <span class="text-blue-500">详情</span>
              </button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 分页器 -->

    <div class="flex justify-between bg-white ml-6 mr-6 pt-4 pb-4">
      <div></div>
      <Pagination
        class="mr-4"
        :currentPage="currentPage"
        :total="total"
        @pageChange="pageChange"
      />
    </div>
  </div>
</template>

<script>
// 懂了轮播图和管理员逻辑  用户管理也一样  就不做多解释了
import Pagination from "../../components/Pagination/pagination";
export default {
  components: { Pagination },
  data() {
    return {
      tableData: [{ nickName: "第一条数据为展示数据" }],
      searchData: {
        id: "",
        nickName: "",
        tel: "",
        status: "",
      },
      formWidth: "100px",
      statusOpt: ["状态1", "状态2"],
      form: {
        total: 0,
        goodTotal: 0,
        badTotal: 0,
      },
      //分页
      total: 0,
      currentPage: 1,
    };
  },
  mounted() {
    // this.getList();
  },
  methods: {
    // 获取数据
    async getList() {
      const RES = await this.http.post("/XXXX/getXXXX", {
        limit: 10,
        page: this.currentPage,
      });
      if (RES.code == 1) {
        this.tableData = RES.data.data;
        this.total = RES.data.total;
      }
    },

    // 详情
    handleDetail(row) {},

    async commonData() {
      const RES = await this.http.post("/XXXX/getXXXX", {
        page: this.currentPage,
        limit: 10,
        entity: {
          id: this.searchData.id,
          nickName: this.searchData.nickName,
          status: this.searchData.status,
          tel: this.searchData.tel,
        },
      });
      if (RES.code == 200) {
        this.tableData = RES.data;
        this.total = RES.total;
      }
    },

    search() {
      this.currentPage = 1;
      this.commonData();
    },
    // 重置
    reset() {
      this.searchData = {
        id: "",
        nickName: "",
        tel: "",
        status: "",
      };
      this.currentPage = 1;
      this.getList();
    },
    // 分页
    pageChange(index) {
      this.currentPage = index._currentPage;
      if (this.checkEmpty(this.searchData)) {
        this.getList();
      } else {
        this.commonData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-table td,
.el-table th {
  text-align: center;
}
/deep/.el-table th {
  text-align: center;
  background: rgb(236, 235, 235);
}
// /deep/.el-table__body {
//   // 使表格兼容safari，不错位
//   width: 100%;
//   table-layout: fixed !important;
// }
</style>