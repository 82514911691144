import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    user: {
      username: "",
      password: "",
    },
  },
  mutations: {
    // 改变登陆状态 1 为登录  0未登录
    changeLogin(state, status) {
      state.token = status
    },
    //存储用户信息到本地  具体看需求
    setUser(state, user) {
      state.user.username = user.account
      state.user.password = user.password
      state.user.name = user.name
      state.user.imgUrl = user.imgUrl
    },
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage, //可以改成localStore
      reducer(val) {
        //设置想要持久化保存的数据
        return {
          user: val.user,
        }
      },
    }),
  ],
})